@font-face {
    font-family: Poppins;
    font-weight: 400;
    src: url(./App/_shared/fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
    font-family: Poppins;
    font-weight: 500;
    src: url(./App/_shared/fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
    font-family: Poppins;
    font-weight: 600;
    src: url(./App/_shared/fonts/Poppins/Poppins-SemiBold.ttf);
}
@font-face {
    font-family: Poppins;
    font-weight: 700;
    src: url(./App/_shared/fonts/Poppins/Poppins-Bold.ttf);
}
